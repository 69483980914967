<template>
  <div class="sales-main-container">
    <CRow class="mb-2">
      <CCol md="6" xl="4">
        <div class="d-flex">
          <h1 class="main-header mr-3" v-translate>Sales report</h1>
          <PDropDown :items="reportOptions"
                     :show-item-selector="true"
                     :reset-on-select="false"
                     :font-weight="'font-weight-semi-bold'"
                     @change="allFreePaidDropdownClick"
                     :disabled="loading"/>
        </div>
      </CCol>
      <CCol xl="auto">
        <div class="overflow-auto pb-2">
          <ul class="nav nav-pills chart-pills flex-nowrap">
            <li class="nav-item">
              <a class="nav-link text-capitalize" href="#"
                 :class="{'active': !selectedIndustry && salesTotal, 'disabled': !salesTotal || loading}"
                 @click.prevent="selectIndustry()">
                <translate>All</translate>
              </a>
            </li>
            <li class="nav-item" v-for="(item, index) in industriesSelector" :key="index">
              <a class="nav-link text-capitalize" href="#"
                 :class="{'active': selectedIndustry===item,
                               'disabled': !salesTotal || loading}"
                 @click.prevent="selectIndustry(item)">
                {{ getIndustryConfig(item).label }}
              </a>
            </li>
          </ul>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol class="mb-2">
        <div class="d-flex flex-row align-items-start">
          <div style="min-width: 240px" class="mr-2 mb-2">
            <div class="input-group input-group-sm input-group-date-time" :class="{'datepicker-disabled':loading}">

              <VueCtkDateTimePicker
                  v-model="selectedDateRange"
                  @validate="onSelectRange($event)"
                  @input="onDateInput($event)"
                  color="#1867FF"
                  :range="true"
                  :auto-close="true"
                  :no-button="false"
                  :input-size="`sm`"
                  :no-label="true"
                  :label="$gettext('Select a date range')"
                  format="YYYY-MM-DD"
                  formatted="L"
                  :shortcut="calendarDefaultShortcut"
                  :custom-shortcuts="datePickerShortcuts"
                  :locale="language"
                  :no-button-now="true"/>
                  <div class="input-group-append">
                <span class="input-group-text"><CIcon name="cipCalendar"></CIcon></span>
              </div>
            </div>

            <div class="mt-1 ml-5 mr-4" style="font-size: 12px;font-style: italic">
              <translate>Time zone:</translate>
              {{ timeZone.EUROPE_ROME }}
            </div>
          </div>

          <div class="mb-2">
            <CInput block type="search" v-model="filterPhotoServiceName" class="input-group-clean"
                    @keyup.enter="filterByPhotoService($event)"
                    :placeholder="$gettext('Search')"
            >
              <template #append>
                <CButton @click="filterByPhotoService($event)">
                  <CIcon name="cip-search2" class="text-secondary" size="custom" :height="14"/>
                </CButton>

              </template>
            </CInput>
          </div>
        </div>

      </CCol>
    </CRow>
    <ViewLoadingMessage :loading="loading"/>
    <template v-if="!loading">
      <CRow class="my-2">
        <CCol md="auto" class="mb-2">
          <sales-report-widget-highlight title="Sales"
                                         :total="totalAmountEur|currency(currencyCodes.EUR)"
                                         :items="itemsB2C"
                                         :vat="$gettext('VAT incl.')"
                                         v-if="salesTotal"
                                         :variant="`blue`"
          />
        </CCol>
        <CCol md="auto" class="mb-2">
          <div class="weird-sep"></div>
        </CCol>
        <CCol md="auto" class="mb-2">
          <sales-report-widget-highlight :title="$gettext('SaaS')"
                                         :total="turnover|currency(currencyCodes.EUR)"
                                         :vat="$gettext('VAT excl.')"
                                         :variant="`green`"
                                         v-if="salesTotal"/>
        </CCol>

        <CCol md="auto" class="mb-2 badge-">
          <div class="weird-sep"></div>
        </CCol>
        <CCol md="auto" class="mb-2">
          <sales-report-widget-highlight :title="$gettext('Pre-sale')"
                                         :total="preSale|currency(currencyCodes.EUR)"
                                         :vat="$gettext('VAT excl.')"
                                         :variant="`yellow`"
                                         v-if="salesTotal"/>
        </CCol>

      </CRow>

      <template v-if="salesTotal">
        <CCard class="chart-card chart-card--blue" v-for="(item, index) in industries" :key="index">
          <CCardHeader class="border-bottom-0 py-2">
            <div class="d-flex align-items-center">
              <h5 class="my-2">{{ getIndustryConfig(item.industry).label }}</h5>
              <div class="ml-2">
                <template>
                  ({{ item.order_count|number }})
                </template>
              </div>
            </div>
            <div class="card-header-actions">
              <div class="d-flex align-items-center">
                <div class="text-primary d-md-down-none">
                  <CDropdown
                      placement="bottom-end"
                      :caret="false"
                      :offset="[0,0]"
                  >
                    <template #toggler-content>
                      <div class="text-primary">
                        Currency
                        <CIcon name="cipInfo" size="lg"/>
                      </div>
                    </template>
                    <div class="p-2">
                      <CListGroup flush class="list-striped" v-if="item.total_amount_per_currency"
                                  style="min-width: 300px">
                        <CListGroupItem v-for="(amount, index) in item.total_amount_per_currency" :key="index"
                                        class="flex-column align-items-start border-bottom-0">
                          <div class="d-flex w-100 justify-content-between">
                            <span>({{ amount.order_count|number }})</span>
                            <span class="font-weight-semi-bold">{{ amount.amount|currency(amount.currency) }}</span>
                          </div>
                        </CListGroupItem>
                      </CListGroup>
                      <div v-else class="text-center font-weight-semi-bold">
                        <translate>No data</translate>
                      </div>
                    </div>
                  </CDropdown>
                </div>

                <h6 class="dot dot-blue font-weight-bold m-0" style="font-size: 15px">
                  {{ item.total_amount_eur|currency(currencyCodes.EUR) }}
                </h6>

                <h6 class="dot dot-green font-weight-bold m-0 ml-3" style="font-size: 15px">
                  {{ item.turnover|currency(currencyCodes.EUR) }}
                </h6>

                <h6 class="dot dot-yellow font-weight-bold m-0 ml-3" style="font-size: 15px">
                  {{ item.presale|currency(currencyCodes.EUR) }}
                </h6>

                <CButton :class="['btn-grid-toggle', 'pr-0', {'on': item._toggled}]"
                         @click="toggleIndustryDetails(item)"/>
              </div>

            </div>
          </CCardHeader>
          <div class="d-flex flex-column flex-md-row secondary-header-industry  justify-content-between">
            <template v-if="isIndustryER(item.industry)">
              <div class="mx-1">
                <translate>Avg purchase</translate>
                <span class="font-weight-semi-bold mx-2">
                  {{
                    getAverage(item.total_amount_eur, item.order_count)|currency(currencyCodes.EUR)
                  }}</span>
              </div>
              <div class="mx-1">
                <translate>Remarketing</translate>
                ({{ getPercentage(item.total_amount_eur, item.order_from_remarketing_amount_eur)|number }}%)
                <span class="font-weight-semi-bold mx-2">
                  {{
                    item.order_from_remarketing_amount_eur|currency(currencyCodes.EUR)
                  }}</span>
              </div>
              <div class="mx-1" v-if="item.industry===industriesKeys.RESORT">
                <translate>During</translate>
                ({{ getPercentage(item.total_amount_eur, item.order_during_amount_eur)|number }}%)
                <span class="font-weight-semi-bold mx-2">{{
                    item.order_during_amount_eur|currency(currencyCodes.EUR)
                  }}</span>
              </div>
              <div class="mx-1" v-if="item.industry===industriesKeys.RESORT">
                <translate>After</translate>
                ({{
                  getPercentage(item.total_amount_eur, (item.total_amount_eur - item.order_during_amount_eur))|number
                }}%)
                <span class="font-weight-semi-bold mx-2">{{
                    (item.total_amount_eur - item.order_during_amount_eur)|currency(currencyCodes.EUR)
                  }}</span>
              </div>
            </template>
            <template v-else>
              <div class="mx-1">
                <translate>Checkins</translate>
                <span class="font-weight-semi-bold mx-2">{{ item.checkin_count|number }}</span>
              </div>
              <div class="mx-1">
                <translate>Remarketing</translate>
                ({{ getPercentage(item.total_amount_eur, item.order_from_remarketing_amount_eur)|number }}%)
                <span class="font-weight-semi-bold mx-2">
                  {{
                    item.order_from_remarketing_amount_eur|currency(currencyCodes.EUR)
                  }}</span>
              </div>
              <div class="mr-3">
                <translate>Avg photos per guest</translate>
                <span
                    class="font-weight-semi-bold mx-2">{{
                    (item.order_medias_count / item.checkin_count || 0)|number
                  }}</span>
              </div>
              <div class="mr-3">
                <translate>Avg downloads</translate>
                <span
                    class="font-weight-semi-bold mx-2">{{
                    (item.download_count / item.checkin_count || 0)|number
                  }}</span>
              </div>
              <div class="">
                <translate>Avg shares</translate>
                <span
                    class="font-weight-semi-bold mx-2">{{
                    (((item.share_total_count - item.download_count) / item.checkin_count)) || 0 |number
                  }}%</span>
              </div>
            </template>
          </div>

          <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
            <div style="min-height: 100px">
              <CCardBody class="">
                <skeleton :items="1" :type="skeletonType.GRID"
                          v-if="!photoServicesIndustries[item.industry]"></skeleton>
                <!--            <ViewLoadingMessage :loading="!photoServicesIndustries[item.industry]"/>-->

                <template v-if="photoServicesIndustries[item.industry]">
                  <div class="report-summary mb-3"
                       v-for="(photoService, index) in photoServicesIndustries[item.industry].results"
                       :key="index">
                    <div class="justify-content-between d-flex primary-header">
                      <div class="d-flex align-items-center">
                        <h6 class="font-weight-semi-bold my-0 mr-2">{{ photoService.company_name }}</h6>
                        <div v-if="isIndustryER(item.industry)">({{ photoService.totals.order_count|number }})</div>
                      </div>
                      <div class="d-flex align-items-center">

                        <h6 class="dot dot-blue font-weight-semi-bold my-0">
                          {{ photoService.totals.total_amount_eur|currency(currencyCodes.EUR) }}
                        </h6>
                        <h6 class="dot dot-green font-weight-semi-bold my-0 ml-2">
                          {{ photoService.totals.turnover|currency(currencyCodes.EUR) }}
                        </h6>
                        <h6 class="dot dot-yellow font-weight-semi-bold my-0 ml-2">
                          {{ photoService.totals.presale|currency(currencyCodes.EUR) }}
                        </h6>

                        <div>
                          <CButton :class="['btn-grid-toggle', {'on': photoService._toggled}]"
                                   @click="togglePhotographerDetails(photoService)"/>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-column flex-md-row secondary-header justify-content-between">
                      <template v-if="isIndustryER(item.industry)">
                        <div class="mx-1">
                          <translate>Avg purchase</translate>
                          <span class="font-weight-semi-bold mx-2">{{
                              getAverage(photoService.totals.total_amount_eur, photoService.totals.order_count)|currency(currencyCodes.EUR)
                            }}</span>
                        </div>

                        <div class="mx-1">
                          <translate>Remarketing</translate>
                          ({{
                            getPercentage(photoService.totals.total_amount_eur, photoService.totals.order_from_remarketing_amount_eur)|number
                          }}%)
                          <span class="font-weight-semi-bold mx-2">
                          {{ photoService.totals.order_from_remarketing_amount_eur|currency(currencyCodes.EUR) }}
                        </span>
                        </div>
                        <div class="mx-1" v-if="item.industry===industriesKeys.RESORT">
                          <translate>During</translate>
                          ({{
                            getPercentage(photoService.totals.total_amount_eur, photoService.totals.order_during_amount_eur)|number
                          }}%)
                          <span class="font-weight-semi-bold mx-2">{{
                              photoService.totals.order_during_amount_eur|currency(currencyCodes.EUR)
                            }}</span>
                        </div>
                        <div class="mx-1" v-if="item.industry===industriesKeys.RESORT">
                          <translate>After</translate>
                          ({{
                            getPercentage(photoService.totals.total_amount_eur, (photoService.totals.total_amount_eur - photoService.totals.order_during_amount_eur))|number
                          }}%)
                          <span class="font-weight-semi-bold mx-2">{{
                              (photoService.totals.total_amount_eur - photoService.totals.order_during_amount_eur)|currency(currencyCodes.EUR)
                            }}</span>
                        </div>
                      </template>
                      <template v-else>
                        <div class="mx-1">
                          <translate>Check-in</translate>
                          <span class="font-weight-semi-bold mx-2">{{ photoService.totals.checkin_count|number }}</span>
                        </div>
                        <div class="mx-1">
                          <translate>Remarketing</translate>
                          ({{
                            getPercentage(photoService.totals.total_amount_eur, photoService.totals.order_from_remarketing_amount_eur)|number
                          }}%)
                          <span class="font-weight-semi-bold mx-2">
                          {{ photoService.totals.order_from_remarketing_amount_eur|currency(currencyCodes.EUR) }}
                        </span>
                        </div>
                        <div class="mx-1">
                          <translate>Avg photos per guest</translate>
                          <span
                              class="font-weight-semi-bold mx-2">{{
                              (photoService.totals.order_medias_count / photoService.totals.checkin_count || 0)|number
                            }}</span>
                        </div>
                        <div class="mx-1">
                          <translate>Avg downloads</translate>
                          <span
                              class="font-weight-semi-bold mx-2">{{
                              (photoService.totals.download_count / photoService.totals.checkin_count || 0)|number
                            }}</span>
                        </div>
                        <div class="mx-1">
                          <translate>Avg shares</translate>
                          <span class="font-weight-semi-bold mx-2">
                        <span
                            class="font-weight-semi-bold mx-2">{{
                            (((photoService.totals.share_total_count - photoService.totals.download_count) / photoService.totals.checkin_count)) || 0 |number
                          }}%</span>
                      </span>
                        </div>
                      </template>
                    </div>
                    <CCollapse :show="Boolean(photoService._toggled)" :duration="collapseDuration">
                      <div style="min-height: 100px"> <!-- necessary for slide anim -->
                        <ViewLoadingMessage
                            :loading="!photoService.events"/>
                        <CCardBody class="p-0"
                                   v-if="photoService.events">
                          <CDataTable
                              :loading="eventsLoading"
                              :striped="true"
                              :sorter='{ external: true, resetable: false }'
                              :sorter-value="sorterValueEvents"
                              :items="photoService.events.results"
                              :fields="getPhotoServiceEventsFields(item.industry)"
                              @update:sorter-value="sortEvents($event, photoService)"
                              class="custom-table custom-table--clean custom-table--nested mb-4">
                            <template #no-items-view>
                              <div class="text-center">
                                <h6 v-translate>No data</h6>
                              </div>
                            </template>
                            <template #sorting-icon="{ state, classes }">
                              <div :class="classes">
                                <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
                                <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
                              </div>
                            </template>

                            <template #pica_service_name="{ item }">
                              <td>
                                <CLink tabindex="-1" :to="{
                                name: 'stats',
                                params: { picaServiceId: item.pica_service_id }}">
                                  {{ item.pica_service_name }}
                                </CLink>
                              </td>
                            </template>
                            <template #checkin_count="{ item }">
                              <td class="">
                                {{ item.checkin_count|number }}
                              </td>
                            </template>
                            <template #order_count="{ item }">
                              <td class="">
                                {{ item.order_count|number }}
                              </td>
                            </template>
                            <template #start_at="{ item }">
                              <td class="">
                                {{ item.start_at|formatDate('L') }}
                              </td>
                            </template>
                            <template #turnover="{ item }">
                              <td class="text-right">
                                {{ item.turnover|currency(currencyCodes.EUR) }}
                              </td>
                            </template>
                            <template #presale="{ item }">
                              <td class="text-right">
                                {{ item.presale|currency(currencyCodes.EUR) }}
                              </td>
                            </template>
                            <template #amount="{ item }">
                              <td class="text-right">
                                <div class="totalEvent">{{ item.amount|currency(item.currency) }}</div>
                                <div class="totalEventEur" v-if="item.currency!==currencyCodes.EUR">
                                  {{ item.amount_eur|currency(currencyCodes.EUR) }}
                                </div>
                              </td>
                            </template>
                          </CDataTable>

                        </CCardBody>

                        <CCardFooter v-if="photoService.events && photoService.events.pagination.num_pages > 1">
                          <CPagination
                              @update:activePage="changePagePhotoServiceEventsTotals(photoService, $event)"
                              :active-page.sync="photoService.events.pagination.page_number "
                              :pages="photoService.events.pagination.num_pages"/>
                        </CCardFooter>
                      </div>
                    </CCollapse>
                  </div>
                  <template v-if="photoServicesIndustries[item.industry].results.length===0">
                    <div class="text-center"><h6 v-translate>No data</h6></div>
                  </template>
                  <!-- LOAD MORE -->
                  <div class="text-center mt-4 mb-3"
                       v-if="photoServicesIndustries[item.industry].pagination.page_number < photoServicesIndustries[item.industry].pagination.num_pages">
                    <ViewLoadingMessage
                        :loading="photoServicesIndustries[item.industry].pagination._loading"/>

                    <CButton variant="outline" color="primary"
                             v-if="!photoServicesIndustries[item.industry].pagination._loading"
                             @click="paginatePhotoServices(item, photoServicesIndustries[item.industry].pagination)">
                      <translate>Load more...</translate>
                      ({{
                        photoServicesIndustries[item.industry].pagination.total - photoServicesIndustries[item.industry].results.length
                      }})
                    </CButton>
                    <!--              {{photoServicesIndustries[item.industry].pagination}}-->
                  </div>
                </template>

              </CCardBody>
            </div>
          </CCollapse>
        </CCard>

      </template>
    </template>
  </div>
</template>

<script>
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import {
  datePickerShortcuts,
  INDUSTRIES_ER,
  industriesConfig,
  industriesKeys
} from "@/views_pica/stats/constants";
import {currencyCodes} from "@/constants";

import SalesReportWidgetHighlight from "@/views_pica/sales_report/SalesReportWidgetHighlight.vue";
import StatsService from "@/services/stats.service";
import PhotoService from "@/services/photo_service.service";
import {photoServiceEventsFields, photoServiceEventsFieldsER, reportOptions} from "@/views_pica/sales_report/constants";
import {getAverage, getPercentage} from "@/utils/functions";
import Skeleton, {skeletonType} from "@/components/Skeleton.vue";
import {mapGetters} from "vuex";
import {timeZone} from "@/utils/date";
import PDropDown from "@/domain/core/components/PDropDown.vue";


export default {
  name: "SalesReport",

  components: {PDropDown, Skeleton, SalesReportWidgetHighlight, ViewLoadingMessage},
  data() {
    return {
      collapseDuration: 0,
      loading: false,
      selectedDateRange: {},
      salesTotal: null,
      industriesData: null,
      selectedIndustry: null,
      photoServicesIndustries: {},
      sorterValueEvents: null,
      eventsLoading: false,
      filterPhotoServiceName: null,
      isPromo: null
    }
  },
  computed: {
    ...mapGetters('event', ['resortCategoryId']),
    reportOptions() {
      return Object.values(reportOptions);
    },
    timeZone() {
      return timeZone
    },
    datePickerShortcuts() {
      return datePickerShortcuts
    },
    skeletonType() {
      return skeletonType
    },
    industriesKeys() {
      return industriesKeys
    },
    currencyCodes() {
      return currencyCodes
    },
    photoServiceEventsFields() {
      let fields = Object.values(photoServiceEventsFields)

      if (!this.hasPerm('dashboard.change_event_turnover')) {
        fields = fields.filter(x => x.key !== 'turnover')
      }
      return fields
    },
    photoServiceEventsFieldsER() {
      return Object.values(photoServiceEventsFieldsER)
    },
    industriesConfig() {
      return industriesConfig
    },
    INDUSTRIES_ER() {
      return INDUSTRIES_ER
    },
    calendarDefaultShortcut() {
      return 'today'
    },
    industriesSelector() {
      return Object.keys(this.industriesConfig).filter(item => item !== 'stadium')
    },
    industries() {
      let industries = []
      for (const item of this.salesTotal.by_industry) {
        if (!industries.find(x => x === item.industry)) industries.push(item)
      }

      const industriesConfigToArray = Object.keys(this.industriesConfig)
      industries.sort(function (a, b) {
        return industriesConfigToArray.indexOf(a.industry) - industriesConfigToArray.indexOf(b.industry);
      });
      return industries

    },
    totalAmountEur() {
      return this.salesTotal.total_amount_eur
    },
    totalHandlingFee() {
      return this.salesTotal.total_handling_fee
    },
    turnover() {
      return this.salesTotal.turnover
    },
    preSale() {
      return this.salesTotal.presale
    },
    itemsB2C() {
      return !this.selectedIndustry ? [
        {
          label: this.$gettext('Fees'),
          value: this.$options.filters.currency(this.totalHandlingFee, this.currencyCodes.EUR),
        }
      ] : null
    }
  },
  async mounted() {
    if (!this.calendarDefaultShortcut) await this.getAllData()
  },

  methods: {
    getPercentage,
    getAverage,
    allFreePaidDropdownClick(val) {
      if (val === reportOptions.ALL.value) {
        this.isPromo = null
      } else if (val === reportOptions.FREE.value) {
        this.isPromo = true
      } else if (val === reportOptions.PAID.value) {
        this.isPromo = false
      }
      this.getAllData()
    },
    onDateInput(dateRange) {
      console.log(dateRange)
      if (!dateRange || (dateRange.start && dateRange.end) || (!dateRange.start && !dateRange.end)) {
        this.selectedDateRange = dateRange
        this.getAllData()
      }
    },

    onSelectRange() {
      this.getAllData()
    },

    filterByPhotoService(event) {
      this.getAllData()
    },
    async getAllData() {
      this.loading = true
      // Reset PhotoServiceIndustries
      this.photoServicesIndustries = {}
      this.salesTotal = null
      await this.getSalesTotal()
      //await this.getPhotoServicesIndustry(this.industries)
    },
    selectIndustry(industry) {
      this.selectedIndustry = industry || null
      this.getAllData()
    },
    getIndustryConfig(industry) {
      return this.industriesConfig[industry]
    },
    getParams(industry) {
      let params = {}

      if (this.selectedDateRange && this.selectedDateRange.start) {
        if (this.selectedDateRange.start) params['from'] = this.selectedDateRange.start
        if (this.selectedDateRange.end) params['to'] = this.selectedDateRange.end
      }

      if (!industry && this.selectedIndustry) params['industry'] = this.selectedIndustry
      if (industry) params['industry'] = industry
      params["merge_corporate_and_fun"] = true
      if (this.isPromo === true) {
        params.is_promo = true
      } else if (this.isPromo === false) {
        params.is_promo = false
      }
      return params
    },
    getSalesTotal() {
      const params = this.getParams()
      if (this.filterPhotoServiceName) params.photo_service_name = this.filterPhotoServiceName
      return StatsService.getSalesTotal(params).then(response => {
        this.salesTotal = response.data
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loading = false
      })
    },

    paginatePhotoServices(industry, pagination) {
      this.$set(pagination, '_loading', !pagination._loading)

      let nextPage = pagination.page_number + 1
      this.getPhotoServicesIndustry([industry], {page: nextPage})

    },
    async getPhotoServicesIndustry(industries, args = {}) {
      for (let industry of industries) {
        let currentIndustry = industry.industry

        let photoServices = industry.photo_services
        photoServices = photoServices.filter(x => x.id !== null)

        // Manual pagination
        const pageSize = 1000 // currently disabled to allow sorting
        let page = args.page ?? 1
        let numPages = Math.ceil(photoServices.length / pageSize)

        let from = (page - 1) * pageSize
        let to = page * pageSize

        let photoServicesSliced = photoServices.slice(from, to)
        for (let photoService of photoServicesSliced) {
          let paramsPhotServiceTotal = this.getParams(currentIndustry)
          let photoServiceTotals = await this.getPhotoServiceTotals(photoService.id, paramsPhotServiceTotal)

          photoService.totals = photoServiceTotals.data
        }

        let pagination = {
          "total": photoServices.length,
          "page_size": pageSize,
          "page_number": page,
          "num_pages": numPages
        }

        let newResults = photoServicesSliced
        let allResults = []
        if (this.photoServicesIndustries[industry.industry]) {
          let loadedResults = this.photoServicesIndustries[industry.industry].results
          allResults = loadedResults.concat(newResults)
        } else {
          allResults = newResults
        }

        let sortedResults = allResults.sort((item1, item2) => item1.totals.total_amount_eur - item2.totals.total_amount_eur).reverse()
        let finalObject = {...{results: sortedResults}, ...{pagination: pagination}}
        this.$set(this.photoServicesIndustries, currentIndustry, finalObject)

      }
    },


    getPhotoServices(params) {
      return PhotoService.getPhotoServices(params);
    },
    getPhotoServiceTotals(photoServiceId, params) {
      return StatsService.getPhotoServiceTotals(photoServiceId, params);
    },
    toggleIndustryDetails(item) {
      if (!item._toggled && !item._viewed) {
        this.getPhotoServicesIndustry([item])
      }

      this.$set(item, "_toggled", !item._toggled)
      this.$set(item, "_viewed", true)

      this.collapseDuration = 300
      this.$nextTick(() => {
        this.collapseDuration = 0
      })
    },
    togglePhotographerDetails(item) {
      if (!item._toggled && !item._viewed) {
        this.getPhotoServiceEventsTotals(item)
      }

      this.$set(item, "_toggled", !item._toggled)
      this.$set(item, "_viewed", true)

      this.collapseDuration = 300
      this.$nextTick(() => {
        this.collapseDuration = 0
      })
    },
    async sortEvents(event, item) {
      this.eventsLoading = true
      let params = {
        'order_by': `${event.asc ? '' : '-'}${event.column}`
      }
      await this.getPhotoServiceEventsTotals(item, params)
      this.eventsLoading = false
    },
    async getPhotoServiceEventsTotals(item, params = {}) {
      // default ordering if not passed in params
      if (!params.order_by) {
        this.sorterValueEvents = {
          "asc": false,
          "column": this.isIndustryER(item.totals.industry) ? 'amount' : 'turnover'
        }
        params.order_by = this.isIndustryER(item.totals.industry) ? '-amount' : '-turnover'
      }

      params['industry'] = item.totals.industry

      let finalParams = {
        ...this.getParams(),
        ...params
      }

      const photoServiceEventsTotal = await StatsService.getPhotoServiceEventsTotals(item.id, finalParams)
      this.$set(item, 'events', photoServiceEventsTotal.data)
    },
    changePagePhotoServiceEventsTotals(item, page) {
      this.getPhotoServiceEventsTotals(item, {page: page})
    },
    isIndustryER(industry) {
      return this.INDUSTRIES_ER.includes(industry)
    },
    getPhotoServiceEventsFields(industry) {
      return this.isIndustryER(industry) ? this.photoServiceEventsFieldsER : this.photoServiceEventsFields
    },
    getVatLabel(industry) {
      return this.isIndustryER(industry) ? this.$gettext('VAT incl.') : this.$gettext('VAT excl.')
    },
  }
}
</script>


<style scoped lang="scss"></style>
