<template>
  <div>
    <ul class="list-unstyled skeleton-list" v-if="type===skeletonType.LIST">
      <li v-for="i in items" :key="i" :style="{width: i % 3 === 0 ? '90%': '100%'}"></li>
    </ul>
    <div class="skeleton-grid" v-if="type===skeletonType.GRID">
      <div v-for="i in items" :key="i" class="row">
        <div class="col-12"><div class="item"></div></div>
        <div class="col-8"><div class="item"></div></div>
        <div class="col-4"><div class="item"></div></div>
        <div class="col-10"><div class="item"></div></div>

      </div>
    </div>
    <div class="skeleton-detail-w" v-if="type===skeletonType.DETAIL">
      <div class="row">
        <div class="col" v-for="i in items" :key="i">
          <div class="skeleton-detail">
            <div class="title"></div>
            <div class="text"></div>
            <div class="text text--long"></div>
            <div class="text text--long"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="skeleton-detail-w" v-if="type===skeletonType.DETAIL_SMALL">
      <div class="row">
        <div class="col" v-for="i in items" :key="i">
          <div class="skeleton-detail">
            <div class="text"></div>
            <div class="text text--long"></div>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>

<script>

export const skeletonType = {
  GRID: 'grid',
  LIST: 'list',
  DETAIL: 'detail',
  DETAIL_SMALL: 'detail-small'
}
export default {
  name: "Skeleton",

  data() {
    return {}
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    items: {
      type: Number,
      required: false,
      default: 3
    }
  },
  computed: {
    skeletonType() {
      return skeletonType
    }
  }
}
</script>
<style lang="scss">
.skeleton-grid {
  .item{
      height: 40px;
      border-radius: 8px;
      margin-bottom: 16px;
      background: {
        position: -100vw;
        repeat: no-repeat;
        color: #e8e8e8;
        size: 50%;
        image: linear-gradient(
                to right,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
        );
      }
      animation: shine 2000ms infinite 0ms alternate;

  }
}

.skeleton-list {
  background-color: #ffffff;
  max-height: 638px;
  overflow: hidden;


  li {
    height: 40px;
    border-radius: 8px;
    margin-bottom: 16px;
    background: {
      position: -100vw;
      repeat: no-repeat;
      color: #e8e8e8;
      size: 50%;
      image: linear-gradient(
              to right,
              rgba(#fff, 0) 0,
              rgba(#fff, 0.2) 20%,
              rgba(#fff, 0.5) 60%,
              rgba(#fff, 0)
      );
    }
    // animation: name duration timing-function delay iteration-count direction fill-mode play-state;
    @for $i from 1 through 15 {
      &:nth-child(#{$i}) {
        $width: 100;
        width: unquote($width + '%');
        $delay: 100 + ($i*5) * 0;
        animation: shine 2000ms infinite unquote($delay+'ms') alternate;
      }
    }


  }
}

.skeleton-detail {
  &-w {
    padding: 36px 20px;
  }

  overflow: hidden;
  margin-bottom: 30px;

  .title {
    width: 40%;
    height: 28px;
    margin-bottom: 20px;
    background: {
      position: -100vw;
      repeat: no-repeat;
      color: #cbcbcb;
      size: 50%;
      image: linear-gradient(
              to right,
              rgba(#fff, 0) 0,
              rgba(#fff, 0.2) 20%,
              rgba(#fff, 0.5) 60%,
              rgba(#fff, 0)
      );
    }
    animation: shine 2000ms infinite;
  }

  .text {
    width: 60%;
    height: 20px;
    margin-bottom: 10px;
    background: {
      position: -100vw;
      repeat: no-repeat;
      color: #cbcbcb;
      size: 50%;
      image: linear-gradient(
              to right,
              rgba(#fff, 0) 0,
              rgba(#fff, 0.2) 20%,
              rgba(#fff, 0.5) 60%,
              rgba(#fff, 0)
      );
    }
    animation: shine 2000ms infinite;

    &--long {
      width: 90%;
    }
  }

}

@keyframes shine {
  0% {
    background-position: -100vw;
  }
  100% {
    background-position: 100vw;
  }
}
</style>
