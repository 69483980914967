<template>
  <div>
    <CRow>
      <CCol>
        <div class="sales-report-widget-highlight" :style="`color:${color}`">
          <div class="sales-report-widget-highlight__head">
            <h5>{{ title }} <span>({{ vat }})</span></h5>
          </div>
        </div>
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <div class="d-flex">
          <h6 class="sales-report-widget-highlight__total badge badge-outline font-weight-bold" :class="variantClass">
            {{ total }}
          </h6>
          <div class="ml-4 w-100">
            <div class="d-flex justify-content-between align-items-center" v-for="(item, index) in items" :key="index">
              <div class="sales-report-widget-highlight__label">{{ item.label }}</div>
              <div class="sales-report-widget-highlight__val" :class="item.class">{{ item.value }}</div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "SalesReportWidgetHighlight",


  props: {
    color: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: false,
    },
    total: {
      type: String,
      required: false,
    },
    vat: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      required: false,
      default: "primary",
    },
  },
  computed: {
    variantClass() {
      return `badge-${this.variant}`;
    },
  },
};
</script>

<style scoped></style>
