import client_core from "@/services/clients/client_core";

const PhotoServiceService = {
    getPhotoServices(params) {
        return client_core.get('/dashboard/v1/photo-services/', { params })
    },

    createPhotoService(params) {
        return client_core.post(`/dashboard/v1/photo-services/`, params)
    },

    retrievePhotoService(photoServiceId) {
        return client_core.get(`/dashboard/v1/photo-services/${photoServiceId}/`)
    },

    updatePhotoService(photoServiceId, params) {
        return client_core.patch(`/dashboard/v1/photo-services/${photoServiceId}/`, params)
    },

    getPhotoServiceUsers(photoServiceId) {
        return client_core.get(`/dashboard/v1/photo-services/${photoServiceId}/users/`)
    },

    addUserToPhotoService(photoServiceId, params) {
        return client_core.post(`/dashboard/v1/photo-services/${photoServiceId}/add-user/`, params)
    },

}

export default PhotoServiceService