import {translate} from 'vue-gettext'

const {pgettext: $pgettext} = translate

export const photoServiceEventsFieldsER = {
    'pica_service_name': {
        key: 'pica_service_name',
        label: $pgettext('stats', 'Name'),
        // _style: 'width:280px'
    },
    /*    'checkin_count': {
            key: 'checkin_count',
            label: $pgettext('stats', 'Check-in'),
            // _style: 'width:200px'
        },*/
    // 'checkin_count': {
    //     key: 'checkin_count',
    //     label: $pgettext('stats', 'Check-in'),
    //     // _style: 'width:200px'
    // },
    // 'order_count': {
    //     key: 'order_count',
    //     label: $pgettext('stats', 'Orders'),
    //     // _style: 'width:200px'
    // },
    'turnover': {
        key: 'turnover',
        label: $pgettext('stats', 'Revenues'),
        _style: 'text-align:right'
    },
    'presale': {
        key: 'presale',
        label: $pgettext('stats', 'Pre-sale'),
        _style: 'text-align:right'
    },
    'amount': {
        key: 'amount',
        label: $pgettext('stats', 'Total'),
        _style: 'text-align:right'
    },
}
export const photoServiceEventsFields = {
    'pica_service_name': {
        key: 'pica_service_name',
        label: $pgettext('stats', 'Name'),
        // _style: 'width:280px'
    },
    'start_at': {
        key: 'start_at',
        label: $pgettext('stats', 'Start at'),
        //   _style: 'text-align:right'
    },
    // 'checkin_count': {
    //     key: 'checkin_count',
    //     label: $pgettext('stats', 'Check-in'),
    //     // _style: 'width:200px'
    // },
    'turnover': {
        key: 'turnover',
        label: $pgettext('stats', 'Revenues'),
        _style: 'width:200px'
    },
    'presale': {
        key: 'presale',
        label: $pgettext('stats', 'Pre-sale'),
        _style: 'text-align:right'
    },
    'amount': {
        key: 'amount',
        label: $pgettext('stats', 'Total'),
        _style: 'text-align:right'
    },
}


export const reportOptions = {
    ALL: {
        value: 'all',
        label: $pgettext('stats', 'All'),
    },
    FREE: {
        value: 'free',
        label: $pgettext('stats', 'Free'),
    },
    PAID: {
        value: 'paid',
        label: $pgettext('stats', 'Paid'),
    },
}